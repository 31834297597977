<template>
  <div class="skeleton-profile-referral">
    <Skeletor class="skeleton-profile-referral__title" as="div" />

    <Skeletor class="skeleton-profile-referral__summary-info" as="div" />

    <div class="skeleton-profile-referral__link">
      <Skeletor class="skeleton-profile-referral__link-title" as="div" />
      <Skeletor class="skeleton-profile-referral__link-block" as="div" />
    </div>

    <div class="skeleton-profile-referral__rewards">
      <Skeletor class="skeleton-profile-referral__reward" as="div" />
      <Skeletor class="skeleton-profile-referral__reward" as="div" />
    </div>

    <div class="skeleton-profile-referral__levels">
      <Skeletor class="skeleton-profile-referral__levels__title" as="div" />
      <Skeletor class="skeleton-profile-referral__levels__text-desktop" as="div" />

      <div class="skeleton-profile-referral__levels__items">
        <Skeletor
          v-for="n in LEVELS_COUNT"
          :key="n"
          class="skeleton-profile-referral__levels__item"
          as="div" />
      </div>

      <Skeletor class="skeleton-profile-referral__levels__text-mobile" as="div" />
    </div>

    <div class="skeleton-profile-referral__sort">
      <div class="skeleton-profile-referral__sort__head">
        <Skeletor class="skeleton-profile-referral__sort__title" as="div" />
        <Skeletor class="skeleton-profile-referral__sort__dropdown" as="div" />
      </div>

      <Skeletor class="skeleton-profile-referral__sort__tabs" as="div" />

      <div class="skeleton-profile-referral__sort__table">
        <Skeletor
          v-for="n in TABLE_ROWS_COUNT"
          :key="n"
          class="skeleton-profile-referral__sort__row"
          as="div" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const LEVELS_COUNT = 5;
  const TABLE_ROWS_COUNT = 10;

</script>

<style src="~/assets/styles/components/skeleton-preloader/referral-page.scss" lang="scss" />
